<template>
  <div class='faq'>
    <div class='faq-inner'>
      <h1>Frequently Asked Questions</h1>
      <v-expansion-panels focusable>
        <v-expansion-panel v-for='(faq, i) in faqs' :key='i'>
          <v-expansion-panel-header>{{ faq.question }}</v-expansion-panel-header>
          <v-expansion-panel-content class="mt-4">
            {{ faq.answer }}
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
      <div class="go-to-contact ma-6">
        <p>If you have questions that aren't answered above, let us know!</p>
        <router-link to="/contact"><v-btn>Contact</v-btn></router-link>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Faq',
  data () {
    return {
      faqs: [
        {
          question:
            'Do I need to have music experience to participate in KindMusic?',
          answer: 'Nope! This is a beginner-friendly workshop.',
        },
        {
          question: 'When will I receive my final song?',
          answer:
            'In 7-14 business days, via the email address you provide us.',
        },
        {
          question: 'What if I don’t like my song?',
          answer:
            'While you can sign up with us for another KindMusic workshop, we do not offer refunds or workshop credits on this basis.',
        },
        {
          question: 'Can large groups participate in KindMusic?',
          answer:
            'Absolutely! We recommend large groups be capped at 30 participants.',
        },
        {
          question:
            'Do you have plans to expand? More language offerings, more music styles?',
          answer: 'We DO! Stay tuned!',
        },
      ],
    }
  },
}
</script>

<style lang='scss' scoped>
.faq-inner {
  width: 90%;
  max-width: 900px;
  margin: auto;
  h1 {
    text-align: center;
    margin: 40px;
  }
  .go-to-contact{
    text-align: center;
  }
}
</style>
