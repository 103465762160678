<template>
  <div class="terms">
    <div class="terms-inner">
      <h1>TERMS &amp; CONDITIONS</h1>
      <h2>TERMS OF SERVICE</h2>

      <h2>OVERVIEW</h2>

      <p>This website is operated by KindMusic, a division of Katelle Foundation, Inc. (“KindMusic”). Throughout the site, the terms “we”, “us” and “our” refer to KindMusic. KindMusic offers this website, including all information, tools, and services available from this site to you, the user, conditioned upon your acceptance of all terms, conditions, policies, and notices stated hereunder.</p>
      <p>By visiting our site and/ or purchasing something from us, you engage in our “Service” and agree to be bound by the following terms and conditions (“Terms of Service”, or “Agreement”), including those additional terms and conditions and policies referenced herein and/or available by hyperlink. These Terms of Service apply to all users of the site and/or the Service, including without limitation users who are browsers, vendors, customers, merchants, and/ or contributors of content.</p>
      <p>Please read these Terms of Service carefully before accessing or using our website or Service. By accessing or using any part of the site, you agree to be bound by these Terms of Service. If you do not agree to all the terms and conditions of this Agreement, then you may not access the website or use any services.</p>
      <p>Any new Service or product offerings which are added to the current store will also be subject to the Terms of Service. You can review the most current version of the Terms of Service at any time on this page. We reserve the right to update, change, or replace any part of these Terms of Service by posting updates and/or changes to our website. It is your responsibility to check this page periodically for changes. Your continued use of or access to the website or Service following the posting of any changes constitutes acceptance of those changes.</p>
      <p>You hereby agree, on behalf of yourself and any entity for which you are signing on behalf of as its authorized representative, to the following:</p>

      <h2>1. ONLINE STORE TERMS.</h2>

      <p>By agreeing to these Terms of Service, you represent that you are at least the age of majority in your state or province of residence, or that you are the age of majority in your state or province of residence, and you have given us your consent to allow any minor for whom you are the parent or guardian to use this site.</p>
      <p>You will not use our products for any illegal or unauthorized purpose nor will you, in the use of the Service, violate any laws in your jurisdiction (including, without limitation, copyright laws).</p>
      <p>In accessing or using our website or Service, you will not transmit any worms or viruses or any code of a destructive nature.</p>
      <p>A breach or violation of any of the Terms of Service will result in an immediate termination of your Service.</p>

      <h2>2. GENERAL CONDITIONS.</h2>
      <p>We reserve the right to refuse service to anyone for any legally permissible reason at any time.</p>
      <p>You understand that your personal information (not including credit card information), may be transferred unencrypted and involve (a) transmissions over various networks; and (b) changes to conform and adapt to technical requirements of connecting networks or devices. Credit card information is always encrypted during transfer over networks.</p>
      <p>You agree not to reproduce, duplicate, copy, sell, resell, or exploit any portion of the Service, use of the Service, or access to the Service or any contact on the website through which the Service is provided, without our express written permission.</p>
      <p>The headings used in this Agreement are included for convenience only and will not limit or otherwise affect these Terms of Service.</p>

      <h2>3. ACCURACY OF INFORMATION.</h2>
      <p>We are not responsible if information made available on this site is not accurate, complete, or current. Any reliance on the material on this site is at your own risk.</p>
      <p>This site may contain certain historical or archival information. Historical information, necessarily, is not current and is provided for your reference only. We reserve the right to modify the contents of this site at any time, but we have no obligation to update any information on our site. You agree that it is your responsibility to monitor changes to our site.</p>

      <h2>4. MODIFICATIONS TO THE SERVICE AND PRICES.</h2>
      <p>Prices for our products are subject to change without notice.</p>
      <p>We reserve the right at any time to modify or discontinue the Service (or any part or content thereof) without notice at any time.</p>
      <p>We will not be liable to you or to any third-party for any modification, price change, suspension, or discontinuance of the Service.</p>

      <h2>5. PRODUCTS OR SERVICES; CONTENT.</h2>

      <p>a. Certain products or services may be available exclusively online through the website. We reserve the right, but are not obligated, to limit the sales of our products or Services to any person, geographic region, or jurisdiction. We may exercise this right on a case-by-case basis. We reserve the right to limit the quantities of any products or services that we offer. All descriptions of products or product pricing are subject to change at any time without notice, at the sole discretion of us. We reserve the right to discontinue any product at any time. Any offer for any product or service made on this site is void where prohibited.</p>
      <p>We do not warrant that the quality of any products, services, information, or other material purchased or obtained by you will meet your expectations, or that any errors in the Service will be corrected.</p>
      <p>b. In consideration of your use of our Service, for any content created as part of your use of the Service, which includes, without limitation, photographs, audio and video recordings, song lyrics, and/or any other copyrightable material (such content collectively referred to as the “Content”), you agree to the following:</p>
      <p>i. Content created as part of your use of the Service, wither alone or jointly with others, will be considered a “work made for hire,” and any ownership of any and all intellectual property rights, including without limitation, copyrights in such content, will belong to and will be owned exclusively by KindMusic. In the event that any portion of the Content should be deemed not to be a “work made for hire” for any reason, you hereby assign, convey, transfer and grant to KindMusic all of your right, title, and interest in and to the Content and any copyrights therein, and agree to cooperate with KindMusic in the execution of appropriate instruments assigning and evidencing such ownership rights as contemplated hereunder.</p>
      <p>ii. KindMusic grants to you a limited, revocable, license to promote, reproduce, display, transmit and/or otherwise use Content solely for non-sponsored, non-monetized purposes on your wholly owned website and your social media channels. All other use of Content requires the prior written consent of KindMusic. All rights not explicitly licensed herein are explicitly reserved by KindMusic.</p>

      <h2>6. ACCURACY OF BILLING AND ACCOUNT INFORMATION.</h2>
      <p>We reserve the right to refuse any order you place with us. We may, in our sole discretion, limit or cancel quantities purchased per person, per household or per order. These restrictions may include orders placed by or under the same customer account, the same credit card, and/or orders that use the same billing and/or shipping address. In the event that we make a change to or cancel an order, we may attempt to notify you by contacting the e-mail and/or billing address/phone number provided at the time the order was made. We reserve the right to limit or prohibit orders that, in our sole judgment, appear to be placed by dealers, resellers or distributors.</p>
      <p>You agree to provide current, complete, and accurate purchase and account information for all purchases made at our store. You agree to promptly update your account and other information, including your email address and credit card numbers and expiration dates, so that we can complete your transactions and contact you as needed.</p>

      <h2>7. OPTIONAL TOOLS.</h2>
      <p>We may provide you with access to third-party tools over which we neither monitor nor have any control nor input.</p>
      <p>You acknowledge and agree that we provide access to such tools ”as is” and “as available” without any warranties, representations, or conditions of any kind and without any endorsement. We will have no liability whatsoever arising from or relating to your use of optional third-party tools.</p>
      <p>Any use by you of optional tools offered through the site is entirely at your own risk and discretion and you should ensure that you are familiar with and approve of the terms on which tools are provided by the relevant third-party provider(s).</p>
      <p>We may also, in the future, offer new services and/or features through the website (including, the release of new tools and resources). Such new features and/or services will also be subject to these Terms of Service.</p>

      <h2>8. THIRD-PARTY CONTENT.</h2>
      <p>Certain content, products, and services available via our Service may include materials from third parties.</p>
      <p>Third-party links on this site may direct you to third-party websites that are not affiliated with us. We are not responsible for examining or evaluating the content or accuracy and we do not warrant and will not have any liability or responsibility for any third-party materials or websites, or for any other materials, products, or services of third parties.</p>
      <p>We are not liable for any harm or damages related to the purchase or use of goods, services, resources, content, or any other transactions made in connection with any third-party websites. Please review carefully the third-party's policies and practices and make sure you understand them before you engage in any transaction. Complaints, claims, concerns, or questions regarding third-party products should be directed to the third-party.</p>

      <h2>9. PERSONAL INFORMATION.</h2>
      <p>Your submission of personal information through the store is governed by our Privacy Policy. We will never share or sell your email address or data.</p>

      <h2>10. ERRORS, INACCURACIES AND OMISSIONS.</h2>
      <p>Occasionally there may be information on our site or in the Service that contains typographical errors, inaccuracies or omissions that may relate to product descriptions, pricing, promotions, offers, product shipping charges, transit times and availability. We reserve the right to correct any errors, inaccuracies or omissions, and to change or update information or cancel orders if any information in the Service or on any related website is inaccurate at any time without prior notice (including after you have submitted your order).</p>
      <p>We undertake no obligation to update, amend or clarify information in the Service or on any related website, including without limitation, pricing information, except as required by law. No specified update or refresh date applied in the Service or on any related website, should be taken to indicate that all information in the Service or on any related website has been modified or updated.</p>

      <h2>11. PROHIBITED USES.</h2>
      <p>In addition to other prohibitions as set forth in the Terms of Service, you are prohibited from using the site or its content: (a) for any unlawful purpose; (b) to solicit others to perform or participate in any unlawful acts; (c) to violate any international, federal, provincial or state regulations, rules, laws, or local ordinances; (d) to infringe upon or violate our intellectual property rights or the intellectual property rights of others; (e) to harass, abuse, insult, harm, defame, slander, disparage, intimidate, or discriminate based on gender, sexual orientation, religion, ethnicity, race, age, national origin, or disability; (f) to submit false or misleading information; (g) to upload or transmit viruses or any other type of malicious code that will or may be used in any way that will affect the functionality or operation of the Service or of any related website, other websites, or the Internet; (h) to collect or track the personal information of others; (i) to spam, phish, pharm, pretext, spider, crawl, or scrape; (j) for any obscene or immoral purpose; or (k) to interfere with or circumvent the security features of the Service or any related website, other websites, or the Internet. We reserve the right to terminate your use of the Service or any related website for violating any of the prohibited uses.</p>

      <h2>12. DISCLAIMER OF WARRANTIES;LIMITATION OF LIABILITY.</h2>
      <p>We do not guarantee, represent or warrant that your use of our service will be uninterrupted, timely, secure or error-free.</p>
      <p>You agree that from time to time we may remove the service for indefinite periods of time or cancel the service at any time, without notice to you.</p>
      <p>You expressly agree that your use of, or inability to use, the Service is at your sole risk. The Service and all products and services delivered to you through the Service are (except as expressly stated by us) provided 'as is' and 'as available' for your use, without any representation, warranties or conditions of any kind, either express or implied, including all implied warranties or conditions of merchantability, merchantable quality, fitness for a particular purpose, durability, title, and non-infringement.</p>
      <p>In no case will KindMusic, our directors, officers, employees, affiliates, agents, contractors, interns, suppliers, service providers or licensors be liable for any injury, loss, claim, or any direct, indirect, incidental, punitive, special, or consequential damages of any kind, including, without limitation lost profits, lost revenue, lost savings, loss of data, replacement costs, or any similar damages, whether based in contract, tort (including negligence), strict liability or otherwise, arising from your use of any of the service or any products procured using the service, or for any other claim related in any way to your use of the service or any product, including WITHOUT LIMITATION, any errors or omissions in any content, or any loss or damage of any kind incurred as a result of the use of the service or any content (or product) posted, transmitted, or otherwise made available via the service, even if advised of their possibility. Because some states or jurisdictions do not allow the exclusion or the limitation of liability for consequential or incidental damages, in such states or jurisdictions, our liability will be limited to the maximum extent permitted by law.</p>

      <h2>13. INDEMNIFICATION.</h2>
      <p>You agree to indemnify, defend, and hold harmless KindMusic and our parent, subsidiaries, affiliates, partners, and each of their officers, directors, agents, contractors, licensors, service providers, subcontractors, suppliers, interns and employees from any claim or demand, including reasonable attorneys’ fees, made by any third-party due to or arising out of your breach of these Terms of Service or the documents they incorporate by reference, or your violation of any law or the rights of a third-party, including, without limitation, any intellectual property rights of such third parties.</p>

      <h2>14. SEVERABILITY.</h2>
      <p>In the event that any provision of these Terms of Service is determined to be unlawful, void, or unenforceable, such provision will nonetheless be enforceable to the fullest extent permitted by applicable law, and the unenforceable portion will be deemed to be severed from these Terms of Service, such determination will not affect the validity and enforceability of any other remaining provisions.</p>

      <h2>15. TERMINATION.</h2>
      <p>These Terms of Service are effective unless and until terminated by us.</p>
      <p>If in our sole judgment you fail, or we suspect that you have failed, to comply with any term or provision of these Terms of Service, we also may terminate this Agreement at any time without notice and you will remain liable for all amounts due up to and including the date of termination; and/or accordingly may deny you access to our Service (or any part thereof).</p>

      <h2>16. ENTIRE AGREEMENT.</h2>
      <p>The failure of us to exercise or enforce any right or provision of these Terms of Service will not constitute a waiver of such right or provision.</p>
      <p>These Terms of Service and any policies or operating rules posted by us on this site or in respect to the Service constitutes the entire agreement and understanding between you and us and govern your use of the Service, superseding any prior or contemporaneous agreements, communications and proposals, whether oral or written, between you and us (including without limitation, any prior versions of the Terms of Service).</p>
      <p>Any ambiguities in the interpretation of these Terms of Service will not be construed against the drafting party.</p>

      <h2>17. GOVERNING LAW; VENUE.</h2>
      <p>These Terms of Service and any separate agreements whereby we provide you Services will be governed by and construed in accordance with the laws of the State of Texas without regard to its principles of conflicts of law provisions. The parties hereby irrevocably submit to the exclusive jurisdiction of the United States federal and state courts located in Travis County, Texas and agree that any such court will be proper forum for the determination of any dispute arising hereunder.</p>

      <h2>18. CHANGES TO TERMS OF SERVICE.</h2>
      <p>You can review the most current version of the Terms of Service at any time at this page.</p>
      <p>We reserve the right, at our sole discretion, to update, change or replace any part of these Terms of Service by posting updates and changes to our website. It is your responsibility to check our website periodically for changes. Your continued use of or access to our website or the Service following the posting of any changes to these Terms of Service constitutes acceptance of those changes.</p>
      <p>Questions about the Terms of Service should be sent to us at info@kindmusic.com.</p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Terms',
}
</script>

<style lang="scss" scoped>
.terms{
    .terms-inner{
        width: 900px;
        max-width: 90vw;
        margin: 0 auto 90px auto;
        h1, h2{
            margin: 40px 0;
        }

    }
}
</style>
