<template>
  <div>
    <h1>Contact Us!</h1>
    <v-form ref="form" v-model="valid" lazy-validation>
      <v-text-field
        v-model="name"
        :counter="30"
        :rules="nameRules"
        label="Name"
        required
      ></v-text-field>

      <v-text-field
        v-model="email"
        :rules="emailRules"
        label="E-mail"
        required
      ></v-text-field>

      <v-textarea
        v-model="message"
        :rules="messageRules"
        label="Message"
        :counter="320"
        outlined
      >
      </v-textarea>

      <div class="submit-wrapper">
        <v-btn :disabled="!valid" color="primary" class="mr-4" @click="validate">
          Submit
        </v-btn>
      </div>
    </v-form>
  </div>

</template>

<script>
export default {
  data: () => ({
    valid: true,
    name: '',
    nameRules: [
      (v) => !!v || 'Name is required',
      (v) => (v && v.length <= 30) || 'Name must be less than 30 characters',
    ],
    email: '',
    emailRules: [
      (v) => !!v || 'E-mail is required',
      (v) => /.+@.+\..+/.test(v) || 'E-mail must be valid',
    ],
    select: null,
    checkbox: false,
    message: '',
    messageRules: [
      (v) => !!v || 'Cannot be left blank',
      (v) => (v && v.length <= 320) || 'Message must be less than 320 characters',
      // (v) => /.+@.+\..+/.test(v) || 'E-mail must be valid'
    ],
  }),

  methods: {
    submit () {
      this.$firebase
        .firestore()
        .collection('contact')
        .add({
          name: this.name,
          date: new Date(),
          email: this.email,
          message: this.message,
        })
        .then((docRef) => {
          console.log('Document written with ID: ', docRef.id)
        })
        .catch(function (error) {
          console.error('Error adding document: ', error)
        })
    },
    validate () {
      if (this.$refs.form.validate()) {
        this.submit()
      }
    },
    reset () {
      this.$refs.form.reset()
    },
    resetValidation () {
      this.$refs.form.resetValidation()
    },
  },
}
</script>

<style lang="scss" scoped>
.submit-wrapper{
  display: flex;
  justify-content: flex-end;
  button{
    margin-right: 0 !important;
  }
}
</style>
