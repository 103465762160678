<template>
    <v-container fill-height fluid>
        <v-row align="center" justify="center">
            <v-card class="card">
                <ContactForm />
            </v-card>
        </v-row>
    </v-container>
</template>

<script>
import ContactForm from '@/components/ContactForm'
export default {
  name: 'contact',
  components: {
    ContactForm,
  },
}
</script>

<style lang="scss" scoped>
    .card{
        width: 900px;
        max-width: 90vw;
        margin: auto;
        padding: 40px
    }
</style>
